import VARIANT from './variant'

export default {
  id: 1,
  count_on_hand: 10,
  backorderable: true,
  stock_location_id: 1,
  variant_id: 1,
  variant: VARIANT
}
