export default {
  id: 3,
  name: 'T-Shirts',
  pretty_name: 'T-Shirts',
  permalink: 'brands/t-shirts',
  parent_id: 1,
  taxonomy_id: 1,
  meta_title: 'T-Shirts',
  meta_description: 'T-Shirts',
  taxons: []
}
