export default {
  id: 2,
  name: 'Ruby on Rails',
  pretty_name: 'Ruby on Rails',
  permalink: 'brands/ruby-on-rails',
  parent_id: 1,
  taxonomy_id: 1,
  meta_title: 'Ruby on Rails',
  meta_description: 'Ruby on Rails',
  taxons: []
}
