import ADDRESS_COUNTRY from './address_country'
import ADDRESS_STATE from './address_state'

export default {
  id: 1,
  firstname: 'Boxid',
  lastname: 'Example',
  full_name: 'Boxid Example',
  address1: 'Skipholt 33',
  address2: 'Bakhús',
  city: 'Reykjavík',
  zipcode: '105',
  phone: '555-5555',
  company: null,
  alternative_phone: null,
  country_id: 1,
  state_id: 1,
  state_name: null,
  state_text: 'NY',
  country: ADDRESS_COUNTRY,
  state: ADDRESS_STATE
}
